import _ from 'lodash'
import moment from 'moment'
import { navigate } from 'gatsby'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../helpers'
import toast from '../helpers/toast'

const BookingContext = createContext('')

const BookingProvider = ({ children }) => {
  const defaultFrom = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  }

  const defaultTo = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date() + 1,
  }

  const { i18n } = useTranslation()
  const [roomsAvailables, setRoomsAvailables] = useState([])
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState(null)
  const [totalPerson, setTotalPerson] = useState(0)
  const [totalRooms, setTotalRooms] = useState([])
  const [totalChildren, setTotalChildren] = useState(0)
  const [totalAdults, setTotalAdults] = useState(2)
  const [diffDays, setDiffDays] = useState(null)
  const [noOfDays, setNoOfDays] = useState(0);
  const [bookFrom, setBookFrom] = useState(defaultFrom)
  const [bookTo, setBookTo] = useState(defaultTo)
  const [maxGuests, setMaxGuests] = useState(0)

  const getAvailableRooms = useCallback(async (params, pathname) => {
    setLoading(true)
    setError(null)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'getAvailableRooms',
      custom: true,
      params: {
        // lang: i18n.language,
        endDate: params.enddate,
        startDate: params.startdate,
        // adults: params.adults,
        // children: params.children,
      },
    }).then((resp) => {
      if (!resp.success) {
        setError(resp.message)
        setLoading(false)
      } else if (resp.success && pathname.includes('/booking/')) {
        setRoomsAvailables(resp.data[0])
        setLoading(false)
      } else {
        navigate('/booking/')
        setRoomsAvailables(resp.data[0])
        setLoading(false)
      }
    //   if(diffDays){
    //     setNoOfDays(diffDays);
    //   }
    }).catch((error) => {
      toast.error('Unable to get available rooms. Please refresh the page.', {
        position: "top-right",
        autoClose: 5000,
      });
    })
  }, [i18n.language])

  useEffect(() => {
    const rooms = _.uniqBy(_.get(roomsAvailables, 'propertyRooms', []), 'roomTypeID')
    const max = _.reduce(rooms, (result, value) => result + (_.toNumber(value.maxGuests) * value.roomsAvailable), 0)
    setMaxGuests(max)
  }, [roomsAvailables])

  const values = {
    loading,
    getAvailableRooms,
    roomsAvailables,
    totalPerson,
    setTotalPerson,
    totalChildren,
    setTotalChildren,
    totalAdults,
    setTotalAdults,
    diffDays,
    setDiffDays,
    noOfDays,
    setNoOfDays,
    totalRooms,
    setTotalRooms,
    bookTo,
    bookFrom,
    setBookTo,
    setBookFrom,
    maxGuests,
    setError,
    err,
  }

  return (
    <BookingContext.Provider value={values}>
      {children}
    </BookingContext.Provider>
  )
}

const useBooking = () => {
  const context = useContext(BookingContext)

  if (!context) { throw new Error('useLocale must be used within an RoomProvider') }

  return context
}

export { BookingProvider, useBooking }
